<template>
    <div class="body fixed mobile">
        <!-- class: fixed, mobile -->
        <!-- Loading Spinner -->
        <!-- <div class="spinner"><div><div></div></div></div> -->
        <Spinner :spin="spin"/>
        <!-- Header -->
        <section class="header stamp">
            <!-- Left -->
            <div class="flexH width">
                <a
                    class="icon iconButton third"
                    @click.prevent="$router.push(goBackPath)"
                >
                    <font-awesome-icon
                        icon="fa-solid fa-chevron-left"
                        size="lg"
                    ></font-awesome-icon>
                </a>
            </div>
            <span class="fL nowrap">集章</span>
            <!-- Right -->
            <div class="flexH width right">
                <a
                    class="icon textButton"
                    @click.prevent="
                        popHistory();
                        getHistory();
                    "
                >
                    <span class="fM third">歷史紀錄</span>
                </a>
            </div>
        </section>
        <!-- Main -->
        <section class="main stamp">
            <span class="fXL third">{{ stamp.stampCardName }}</span>
            <span class="fL">{{ stamp.storeName }}</span>
            <span v-if="isInitialized"  class="fM gray"
                >集章時間 {{ tsToDate(stamp.collectStartTime) }} -
                {{ tsToDate(stamp.collectEndTime) }}</span
            >
            <span v-if="isInitialized"  class="fM gray"
                >兌換時間 {{ tsToDate(stamp.redeemStartTime) }} -
                {{ tsToDate(stamp.redeemEndTime) }}</span
            >
            <!-- 貼紙 -->
            <div class="sticker flexV">
                <div
                    class="flexH"
                    v-for="level in numOfLevel(stamp.stampCardMaxStamps)"
                    :key="'level' + level"
                >
                    <img
                        :src="stamp.stampImageUrl || defaultStampImage"
                        v-for="index in numOfStampByLevel(
                            level,
                            stamp.numOfStamps
                        )"
                        :key="level + 'first-numOfStamp' + index"
                    />
                    <img
                        :src="stamp.stampImageUrl || defaultStampImage"
                        class="none"
                        v-for="index in 5 -
                        numOfStampByLevel(level, stamp.numOfStamps)"
                        :key="level + 'first-numOfStamp-none' + index"
                    />
                </div>
            </div>
            <div v-if="isInitialized" class="dashH"></div>
            <!-- 獎品 -->
            <a
                class="gift flexH width"
                v-for="(reward, index) in stamp.rewards"
                :key="'reward' + index"
                @click.prevent="popGift(reward)"
            >
                <div class="topLeft" v-if="reward.hasRedeemed">
                    <div
                        :class="[
                            'textBg',
                            'flexV',
                            'center',
                            { active: reward.hasRedeemed }
                        ]"
                    >
                        <!-- class: active 切換開關 -->
                        <span class="fS white">已兌換</span>
                        <!-- 已兌換/缺貨 -->
                    </div>
                </div>
                <div class="flexV width padding">
                    <span class="fM">{{ reward.rewardName }}</span>
                    <span class="fS gray double">{{ reward.rewardDesc }}</span>
                    <div class="flexH">
                        <img :src="stamp.stampImageUrl || defaultStampImage" class="stamp" />
                        <span class="fM bold primary">{{
                            reward.rewardRequirement
                        }}</span>
                    </div>
                </div>
                <img :src="reward.rewardImageUrl" class="photo" />
            </a>
            <div v-if="isInitialized"  class="dashH"></div>
            <div
                class="detail flexV width"
                v-for="(desc, index) in stamp.stampCardDesc"
                :key="'desc' + index"
            >
                <span class="fL">{{ desc.title }}</span>
                <span class="fM gray">{{ desc.text }}</span>
            </div>
        </section>
        <!-- Footer -->
        <section class="footer ticket">
            <a
                class="button rounded white"
                v-if="!stamp.collectedAt"
                @click.prevent="popWallet()"
            >
                <!-- 未加入票夾 -->
                <div class="icon primary">
                    <font-awesome-icon
                        icon="fa-solid fa-plus"
                    ></font-awesome-icon>
                </div>
                <span class="fM">加入票夾</span>
            </a>
            <a
                class="button rounded"
                @click.prevent="barcodePopupOpen = true"
            >
                <!-- 非已完成/已過期 -->
                <span class="fM">現場集章</span>
            </a>
        </section>
        <!-- Popup (Code) -->
        <BarcodePopup
            v-if="barcodePopupOpen"
            @closePopup="barcodePopupOpen = false"
        />
        <!-- Popup (Wallet) -->
        <section id="popWallet" class="popup" ref="wallet">
            <div class="popBody">
                <a class="close" @click.prevent="walletClose()">
                    <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
                </a>
                <section class="popMain">
                    <div class="icon">
                        <font-awesome-icon
                            icon="fa-solid fa-circle-check"
                            size="3x"
                            class="success"
                        ></font-awesome-icon>
                    </div>
                    <span class="fL">已加入您的票夾</span>
                </section>
                <section class="popFooter">
                    <a class="button rounded white" @click.prevent="$router.push('/stamp-cards')">
                        <span class="fM primary">前往票夾</span>
                    </a>
                    <a class="button rounded" @click.prevent="walletClose()">
                        <span class="fM">繼續瀏覽</span>
                    </a>
                </section>
            </div>
        </section>
        <!-- Popup (History) -->
        <section class="popup ticket popHistory" ref="history">
            <div class="popBody">
                <a class="close" @click.prevent="historyClose()">
                    <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
                </a>
                <section class="popHeader">
                    <span class="fL primary center">歷史紀錄</span>
                </section>
                <section class="popMain">
                    <div
                        class="record flexH width between"
                        v-for="(transaction, index) in historyData.transactions"
                        :key="'transaction' + index"
                    >
                        <div class="flexV">
                            <span class="fM">{{
                                transaction.rewardName || "消費回饋"
                            }}</span>
                            <span class="fS gray">{{
                                transaction.storeName
                            }}</span>
                        </div>
                        <div class="flexV end">
                            <div class="flexH">
                                <span
                                    class="fM bold primary"
                                    v-if="
                                        transaction.transactionType ==
                                        'redeem-reward'
                                    "
                                    >滿{{
                                        transaction.rewardRequirement
                                    }}個</span
                                >
                                <span
                                    class="fM bold success"
                                    v-else-if="
                                        transaction.transactionType ==
                                        'collect-stamps'
                                    "
                                    >+{{ transaction.amount }}</span
                                >
                                <!-- <img
                                    class="stamp"
                                    src="@/assets/img/stamp.png"
                                /> -->
                                <img :src="stamp.stampImageUrl || defaultStampImage" class="stamp" />
                            </div>
                            <span class="fS gray">{{
                                tsToDatetime(transaction.createdAt)
                            }}</span>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <!-- Popup (Gift) -->
        <section
            class="popup stamp popGift"
            ref="gift"
        >
            <div class="popBody">
                <a class="close" @click.prevent="giftClose()">
                    <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
                </a>
                <section class="popMain">
                    <img v-if="reward.rewardImageUrl" class="photo" :src="reward.rewardImageUrl" />
                    <span class="fXL third">{{reward.rewardName}}</span>
                    <span class="fM gray">兌換時間 {{ tsToDate(stamp.redeemStartTime) }} -
                {{ tsToDate(stamp.redeemEndTime) }}</span>
                    <div class="detail flexH width between">
                        <div class="flexH">
                            <!-- <img src="@/assets/img/stamp.png" class="stamp" /> -->
                            <img :src="stamp.stampImageUrl || defaultStampImage" class="stamp" />
                            <span class="fM bold primary">滿{{reward.rewardRequirement}}個</span>
                        </div>
                        <a class="button rounded fit" >
                            <span class="fM disabled" v-if="checkUnAvaliableRedeem(reward)" @click.prevent>已兌換</span>
                            <span class="fM disabled" v-else-if="checkUnAvaliableNumOfStamp(reward)" @click.prevent>集章不足</span>
                            <span class="fM disabled" v-else-if="checkUnAvaliableTime()" @click.prevent>已截止</span>
                            <span class="fM" v-else-if="checkAvaliableToGet(reward)" @click.prevent="popGiftGet(reward)">兌換獎品</span>
                        </a>
                    </div>
                    <div class="dashH"></div>
                    <div class="detail flexV width">
                        <span class="fL">集章說明</span>
                        <span class="fM gray"
                            >{{reward.rewardDesc}}</span
                        >
                    </div>
                </section>
            </div>
        </section>
        <!-- Popup (GiftGet) -->
        <section
            id="popGiftGet"
            class="popup stamp popGiftGet"
            ref="gift-get"
        >
            <div class="popBody">
                <a class="close" @click.prevent="giftGetClose()">
                    <font-awesome-icon icon="fas fa-times"></font-awesome-icon>
                </a>
                <section class="popMain">
                    <img v-if="reward.rewardImageUrl" class="photo" :src="reward.rewardImageUrl" />
                    <span class="fL">確認兌換 {{reward.rewardName}} ？</span>
                    <span class="fS error"
                        >兌換後將無法取消，請確認已出示給現場人員</span
                    >
                </section>
                <section class="popFooter">
                    <a class="button rounded white" @click.prevent="giftGetClose()">
                        <span class="fM">取消</span>
                    </a>
                    <a class="button rounded" @click.prevent="getGift()">
                        <span class="fM">確定兌換</span>
                    </a>
                </section>
            </div>
        </section>
    </div>
</template>
<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import Spinner from '@/components/Spinner';
import BarcodePopup from '@/components/BarcodePopup.vue';

export default {
    name: "StampCard",
    components: {
        Spinner,
        BarcodePopup,
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            vm.goBackPath = from ?? '/home';
        });
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            memberCode: "",
            intervalId: "",
            defaultTimerSec: 300,
            timer: 300,
            stamp: {},
            historyData: { transactions: [] },
            reward: {},
            selectReward: {},
            spin: false,
            goBackPath: "/home",
            defaultStampImage: "@/assets/img/stamp.png",
            isInitialized: false,
            barcodePopupOpen: false,
        };
    },
    mounted() {
        this.spin = true;
        this.getStamp();
    },
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        dummyStamp() {
            let result = {
                stampCardId: "WJZbm9bgEv",
                stampCardName: "集點卡", //集章標題
                stampCardDesc: [
                    {
                        text: "集滿10點獎勵，下次消費享95折超值優惠(??)",
                        title: "優惠說明"
                    },
                    {
                        text: "在集章兌換期限內都可以。",
                        title: "適用期間"
                    },
                    {
                        text: "拿給老闆看...",
                        title: "兌換方式"
                    },
                    {
                        text: "逾期不候",
                        title: "注意事項"
                    }
                ],
                stampCardMaxStamps: 20, //最大集章數
                bannerImageUrl:
                    "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
                collectStartTime: 1653235200, //集章開始時間
                collectEndTime: 1656604499, //集章截止時間
                redeemStartTime: 1654444800, //兌換開始時間
                redeemEndTime: 1658073599, //兌換截止時間
                storeId: 1,
                storeName: "美又美早餐店桃園店", //特店名稱
                numOfStamps: 20, //目前集章數
                collectedAt: 1653807421,
                rewards: [
                    {
                        rewardId: 1,
                        rewardName: "集滿10點獎勵-消費95折", //獎品名稱
                        rewardDesc: "集滿10點獎勵，下次消費享95折超值優惠(??)",
                        rewardType: "misc", //獎品類型（目前介面不特別顯示）
                        rewardImageUrl:
                            "https://pixabay.com/get/gc6ef793bae8de2ba48931aa710852e82712b1a71a0d907c3e4c10abb484c5e9833b340a43290c042e8a166ae21a5f229fda4980268320539f25be33c314a168896bfa890ebc4f6728f8cac45877417a5_640.png", //獎品圖片（optional）
                        rewardRequirement: 10, //獎品集章需求
                        hasRedeemed: true, // 是否已兌換
                        redeemedAt: 1653816414 //兌換時間
                    },
                    {
                        rewardId: 2,
                        rewardName: "iPhone 13乙支",
                        rewardDesc: "集滿10點獎勵，下次消費享95折超值優惠(??)",
                        rewardType: "misc",
                        rewardImageUrl:
                            "https://pixabay.com/get/g3acf63634a9a8c95bdd22fb36542719aa9b4d9a0e0b415911abe82435ecfc04f1dc67d943546b7be4fdf7c009c708d8c824f25830e2828cfca4da50a28b7bcaf6205ffecc625a3ea69c87612ac5a2c74_640.png",
                        rewardRequirement: 20,
                        hasRedeemed: false
                    }
                ]
            };
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ data: result });
                }, 300);
            });
        },
        callGetStampAPI() {
            let stampCardId = this.$route.params.id;
            let config = {
                url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${stampCardId}/collector-status?uuid=${this.user.userId}`
            };
            console.log("config: ", config)
            return this.$http(config);
        },
        getStamp() {
            this.callGetStampAPI()
            // this.dummyStamp()
                .then((res) => {
                    this.stamp = res.data;
                })
                .catch((err) => console.log(err))
                .finally(() => {
                    this.isInitialized = true;
                    this.spin = false;
                });
        },
        tsToDate(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD");
        },
        tsToDatetime(ts) {
            return moment(ts * 1000).format("YYYY-MM-DD HH:mm:ss");
        },
        numOfStampOfFirstFloor() {
            let numOfStamps = this.stamp.numOfStamps;
            if (numOfStamps <= 5) {
                return numOfStamps;
            } else {
                return 5;
            }
        },
        numOfStampOfSecondFloor() {
            let numOfStamps = this.stamp.numOfStamps;
            if (numOfStamps > 5 && numOfStamps <= 10) {
                return numOfStamps - 5;
            } else {
                return 0;
            }
        },
        numOfStampByLevel(level, numOfStamp) {
            let currentMaxStamps = level * 5;
            if (numOfStamp >= currentMaxStamps) {
                return 5;
            } else {
                if (5 - (currentMaxStamps - numOfStamp) > 0) {
                    return 5 - (currentMaxStamps - numOfStamp);
                } else {
                    return 0;
                }
            }
        },
        numOfLevel(maxStamp = 0) {
            return Math.ceil(maxStamp / 5);
        },
        popHistory() {
            this.$refs["history"].style.display = "flex";
        },
        historyClose() {
            this.$refs["history"].style.display = "none";
        },
        dummyHistory() {
            let result = {
                from: "1999-12-31T16:00:00.000Z",
                to: "2030-12-30T16:00:00.000Z",
                startIndex: 0,
                limit: -1,
                total: 4,
                count: 4,
                transactions: [
                    {
                        storeName: "美又美早餐店桃園店", //特店名稱
                        stampCardId: "WJZbm9bgEv",
                        stampCardName: "集點卡",
                        stampCardDesc: "集滿十點，下次消費九五折",
                        bannerImageUrl:
                            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
                        transactionType: "redeem-reward", // 交易類型
                        amount: 0,
                        rewardName: "iPhone 13乙支",
                        rewardImageUrl:
                            "https://pixabay.com/get/g3acf63634a9a8c95bdd22fb36542719aa9b4d9a0e0b415911abe82435ecfc04f1dc67d943546b7be4fdf7c009c708d8c824f25830e2828cfca4da50a28b7bcaf6205ffecc625a3ea69c87612ac5a2c74_640.png",
                        rewardType: "misc",
                        rewardRequirement: 20,
                        rewardSettings: null,
                        createdAt: 1654919940
                    },
                    {
                        storeName: "美又美早餐店桃園店",
                        stampCardId: "WJZbm9bgEv",
                        stampCardName: "集點卡",
                        stampCardDesc: "集滿十點，下次消費九五折",
                        bannerImageUrl:
                            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
                        transactionType: "collect-stamps",
                        amount: 3,
                        rewardName: null,
                        rewardImageUrl: null,
                        rewardType: null,
                        rewardRequirement: null,
                        rewardSettings: null,
                        createdAt: 1653817025
                    },
                    {
                        storeName: "美又美早餐店桃園店",
                        stampCardId: "WJZbm9bgEv",
                        stampCardName: "集點卡",
                        stampCardDesc: "集滿十點，下次消費九五折",
                        bannerImageUrl:
                            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
                        transactionType: "collect-stamps",
                        amount: 7,
                        rewardName: null,
                        rewardImageUrl: null,
                        rewardType: null,
                        rewardRequirement: null,
                        rewardSettings: null,
                        createdAt: 1653817025
                    },
                    {
                        storeName: "美又美早餐店桃園店",
                        stampCardId: "WJZbm9bgEv",
                        stampCardName: "集點卡",
                        stampCardDesc: "集滿十點，下次消費九五折",
                        bannerImageUrl:
                            "https://pixabay.com/get/g640dec5cd6634a846e3d65f7fe7badba7837d241a311fc0d6d3394dd083aac5e1dccfee71a34851c80c16055fa937565b56836074c2b4f060319b88be396e58def0844b20a32784698ec1bc0534efa68_640.jpg",
                        transactionType: "redeem-reward",
                        amount: -10,
                        rewardName: "集滿10點獎勵-消費95折",
                        rewardImageUrl:
                            "https://pixabay.com/get/gc6ef793bae8de2ba48931aa710852e82712b1a71a0d907c3e4c10abb484c5e9833b340a43290c042e8a166ae21a5f229fda4980268320539f25be33c314a168896bfa890ebc4f6728f8cac45877417a5_640.png",
                        rewardType: "misc",
                        rewardRequirement: 10,
                        rewardSettings: null,
                        createdAt: 1653816414
                    }
                ]
            };
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve({ data: result });
                }, 300);
            });
        },
        callGetHistoryAPI() {
            let stampCardId = this.$route.params.id;
            let config = {
                url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${stampCardId}/collector-transactions?uuid=${this.user.userId}`
            };
            return this.$http(config);
        },
        getHistory() {
            this.spin = true;
            this.callGetHistoryAPI()
            // this.dummyHistory()
                .then((res) => {
                    this.historyData = res.data;
                    this.spin = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.spin = false;
                });
        },
        popGift(reward) {
            this.reward = reward;
            this.$refs['gift'].style.display = "flex";
        },
        giftClose() {
            this.$refs['gift'].style.display = "none";
        },
        popGiftGet(reward) {
            this.selectReward = reward;
            this.$refs['gift-get'].style.display = "flex";
        },
        giftGetClose() {
            this.$refs['gift-get'].style.display = "none";
        },
        getGift() {
            let stampCardId = this.$route.params.id;
            let config = {
                url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${stampCardId}/claim-reward`,
                method: "POST",
                data: {
                    uuid: this.user.userId,
                    rewardId: this.selectReward.rewardId
                }
            };
            this.spin = true;
            this.$http(config)
            .then(res => {
                console.log(res);
                window.location.reload();
                this.spin = false;
            })
            .catch(err => {
                console.log(err);
                this.spin = false;
            });
        },
        checkUnAvaliableNumOfStamp(reward) {
            if(this.stamp.numOfStamps < reward.rewardRequirement) {
                return true;
            } else {
                return false;
            }
        },
        checkUnAvaliableRedeem(reward) {
            if(reward.hasRedeemed) {
                return true;
            } else {
                return false;
            }
        },
        checkUnAvaliableTime() {
            if(Math.floor(Date.now() / 1000) > this.stamp.redeemEndTime) {
                return true;
            } else {
                return false;
            }
        },
        checkAvaliableToGet(reward) {
            if(this.stamp.numOfStamps >= reward.rewardRequirement) {
                return true;
            } else {
                return false;
            }
        },
        addToMyWallet() {
            let stampCardId = this.$route.params.id;
            let config = {
                url: `${this.apiHost}/stamps/v1/merchants/${this.merchantId}/stamp-cards/${stampCardId}/collect`,
                method: "POST",
                data: {
                    uuid: this.user.userId
                }
            };
            return this.$http(config);
        },
        popWallet() {
            this.addToMyWallet()
            .then(() => {
                this.$refs['wallet'].style.display = "flex";
            })
            .catch(err => console.log(err));
        },
        walletClose() {
            this.$refs['wallet'].style.display = "none";
        },
    }
};
</script>
